import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import mainimg from '../Data/pushpa2 main.jpg';
import img1 from '../Data/Pushpa2 img1.jpg';
import img2 from '../Data/Pushpa2 img2.jpg';
import img3 from '../Data/Pushpa2 img3.jpg';
import img4 from '../Data/Pushpa2 img4.jpg';
import img5 from '../Data/Pushpa2 img5.jpg';
import img6 from '../Data/Pushpa2 img6.jpg';
import trimg1 from '../Data/Pushpa2 traimg1.jpg';
import trimg2 from '../Data/Pushpa2 traimg2.jpg';
import trimg3 from '../Data/Pushpa2 traimg3.jpeg';
import caimg1 from '../Data/Pushpa2 cast img1.jpg';
import caimg2 from '../Data/Pushpa2 cast img2.jpeg'; 
import caimg3 from '../Data/Pushpa2 cast img3.jpg';


const Hero1 = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // Movie data
    const movie = {
        title: 'Pushpa 2: The Rule',
        releaseDate: 'December 5, 2024',
        genres: ['Action','Crime', 'Drama', 'Thriller'],
        runtime: '3HRS 21MINS',
        imdb: '6.8/10',
        Director: 'Sukumar',
        Writer: 'A.R. Prabhav - Sukumar - Srikanth Vissa',
        Cast: 'Allu Arjun - Fahadh Faasil - Rashmika Mandanna',
        Wheretowatch: 'You can watch Meiyazhagan ---',
        images: [img1, img2, img3, img4, img5, img6],
        poster: mainimg,
    };

    // Trailers data
    const trailers = [
        {
            id: 1,
            title: 'Pushpa 2 - The Rule Trailer (Hindi) | Allu Arjun | Sukumar | Rashmika Mandanna | Fahadh Faasil | DSP',
            thumbnail: trimg1,
            url: 'https://youtu.be/1kVK0MZlbI4?si=a9ZQ2qdqmAWLk5W7',

        },
        {
            id: 2,
            title: 'Angaaron (The Couple Song) Lyrical | Pushpa 2 The Rule | Allu Arjun |Rashmika |Sukumar |DSP, Shreya',
            thumbnail: trimg2,
            url: 'https://youtu.be/OgRoRBLZbUQ?si=nAU1lL0lf9w5cz2r',

        },
        {
            id: 3,
            title: 'Pushpa 2 Movie Review By bnftv',
            thumbnail: trimg3,
            url: 'https://youtu.be/fykmUadWgNQ?si=9CMQNxGCfpgQIjCR',
        },
    ];

    // characters data
    const characters = [
        {
            name: "Allu Arjun as Pushpa Raj",
            image: caimg1,
            description:
                "Pushpa Raj (Arjun) is now a big-time red sanders smuggler. He lives in a lavish bungalow with his loving wife, Srivalli (Rashmika Mandanna) and his mother (Kalpalatha). He wears flashy shirts, jewellery, and sports a painted red nail now, as a sign of his economic status",
            imageFirst: false,
        },
        {
            name: "Fahadh Faasil as Bhanwar Singh Shekhawat IPS",
            image: caimg2,
            description: "Fahadh Faasil plays the role of Bhanwar Singh Shekhawat, an IPS officer, in the movie Pushpa 2: The Rule. Here are some details about the character: Appearance Fahadh's character has a shaved head, a scar on his left side, and a sharp look on his face. ",
           imageFirst: true,
        },
        {
            name: "Rashmika Mandanna as Srivalli",
            image: caimg3,
            description: "Rashmika Mandanna plays the character of Srivalli in the Pushpa film franchise. Here are some details about her portrayal of Srivalli: Love interest: Srivalli is the love interest of Pushpa.  Reprising the role: Mandanna reprised her role as Srivalli in the sequel, Pushpa 2: The Rule. Portrayal as Pushpa's wife: In the sequel, Srivalli is depicted as Pushpa's wife. ",
            
            
            imageFirst: false,
        },
    ];

    const openImage = (index) => setSelectedImageIndex(index);
    const closeImage = () => setSelectedImageIndex(null);
    const handleNext = () =>
        setSelectedImageIndex((prevIndex) =>
            prevIndex === movie.images.length - 1 ? 0 : prevIndex + 1
        );
    const handlePrevious = () =>
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? movie.images.length - 1 : prevIndex - 1
        );

    return (
        <main className="bg-gray-100">
            <section className="relative">
                {/* Background Image */}
                <div className="absolute inset-0" aria-hidden="true">
                    <img
                        src={movie.poster}
                        alt={`Poster of ${movie.title}`}
                        className="w-full h-full object-cover opacity-30"
                    />
                </div>

                {/* Movie Details */}
                <div className="relative px-4 py-10 max-w-7xl mx-auto">
                    <header className="flex flex-col md:flex-row items-center gap-4 md:gap-8">
                        {/* Poster */}
                        <div className="flex-shrink-0">
                            <img
                                src={movie.poster}
                                alt={`Official movie poster of ${movie.title}`}
                                className="w-48 h-auto rounded-md shadow-lg"
                            />
                        </div>

                        {/* Movie Information */}
                        <article className="text-left">
                            <h1 className="text-4xl font-bold text-black">{movie.title}</h1>
                            <p className="text-gray-800 mt-2">
                                <span className="block">
                                    <strong>Release Date:</strong> {movie.releaseDate}
                                </span>
                                <span className="block">
                                    <strong>Genres:</strong> {movie.genres.join(', ')}
                                </span>
                                <span className="block">
                                    <strong>Runtime:</strong> {movie.runtime}
                                </span>
                                <span className="block">
                                    <strong>Imdb Rating:</strong> {movie.imdb}
                                </span>
                                <span className="block">
                                    <strong>Director:</strong> {movie.Director}
                                </span>
                                <span className="block">
                                    <strong>Writer:</strong> {movie.Writer}
                                </span>
                                <span className="block">
                                    <strong>Cast:</strong> {movie.Cast}
                                </span>
                                <span className="block">
                                    <strong>Where to Watch:</strong> {movie.Wheretowatch}
                                </span>
                            </p>
                        </article>
                    </header>

                    {/* Photo Gallery */}
                    <section className="max-w-7xl mx-auto px-4 py-8">
                        <h2 className="text-2xl font-bold mb-4">
                            Photo Gallery of {movie.title}
                        </h2>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                            {movie.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Scene ${index + 1} from ${movie.title}`}
                                    className="w-full h-48 object-cover rounded-md shadow-sm cursor-pointer"
                                    onClick={() => openImage(index)}
                                />
                            ))}
                        </div>
                    </section>
                    <section className="p-8" aria-labelledby="trailers-section">
                        <h2 id="trailers-section" className="text-2xl font-bold mb-6 text-center">
                            Trailers & Clips of {movie.title}
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {trailers.map((trailer) => (
                                <article
                                    key={trailer.id}
                                    className="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                                >
                                    <a
                                        href={trailer.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={`Watch trailer: ${trailer.title}`}
                                    >
                                        {/* Image with fixed aspect ratio */}
                                        <div className="aspect-w-16 aspect-h-9 relative">
                                            <img
                                                src={trailer.thumbnail}
                                                alt={`Thumbnail for ${trailer.title}`}
                                                className="w-full h-full object-cover"
                                            />
                                            {/* Always visible title */}
                                            <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-60 text-white text-sm md:text-lg font-semibold text-center p-2">
                                                {trailer.title}

                                            </div>
                                        </div>
                                    </a>

                                </article>
                            ))}
                        </div>
                    </section>
                    <section className="w-full min-h-screen py-12">
                        <div className="container mx-auto px-6">
                            {characters.map((character, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col lg:flex-row items-center justify-between gap-8 mb-16 ${character.imageFirst ? "lg:flex-row-reverse" : ""
                                        }`}
                                >
                                    {/* Character Image */}
                                    <div className="w-48 h-48 lg:w-64 lg:h-64 rounded-full overflow-hidden border-4 border-black shadow-lg">
                                        <img
                                            src={character.image}
                                            alt={character.name}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>

                                    {/* Character Details */}
                                    <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
                                        <h1 className="text-3xl lg:text-5xl font-bold text-black">
                                            {character.name}
                                        </h1>
                                        <p className="mt-4 text-black leading-relaxed">{character.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </section>

            {/* Modal for Image Slider */}
            {selectedImageIndex !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50">
                    {/* Current Image */}
                    <div className="relative max-w-4xl max-h-screen">
                        <img
                            src={movie.images[selectedImageIndex]}
                            alt={`Viewing image ${selectedImageIndex + 1} from ${movie.title}`}
                            className="object-contain w-full h-full"
                        />

                        {/* Close Button */}
                        <button
                            className="absolute top-4 right-4 text-white text-2xl bg-black bg-opacity-50 p-3 rounded-full shadow-md"
                            aria-label="Close"
                            onClick={closeImage}
                        >
                            <FaTimes />
                        </button>

                        {/* Previous Button */}
                        <button
                            className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 p-3 rounded-full shadow-md"
                            aria-label="Previous Image"
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePrevious();
                            }}
                        >
                            <FaChevronLeft />
                        </button>

                        {/* Next Button */}
                        <button
                            className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 p-3 rounded-full shadow-md"
                            aria-label="Next Image"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleNext();
                            }}
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Hero1;
