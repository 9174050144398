import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Pushpa 2: The Rule</h2>   
        <div className="mt-2">
          <a
            href="https://snapmoviehd.com/download/Pushpa-The-Rule-Part2"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-600"
          >
            Pushpa 2: The Rule Download Full Movie
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
